import React, { useEffect, useState } from "react";
import { Header2, Footer, StartSearch } from "./features";
import HomePage from "./pages/home-page";
import { Box } from "@mui/material";
import logoBg from "./assets/images/tolom-bg.webp";
import { FaqBlock } from "./components";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

function App() {
    const screenWidth = window.innerWidth;
    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0)

    useEffect(() => {
        const listenToScroll = () => {
            let heightToHideFrom = 200;
            const winScroll = document.body.scrollTop ||
                document.documentElement.scrollTop;
            setHeight(winScroll);

            if (winScroll > heightToHideFrom) {
                isVisible && setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        };
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenWidth])

    return (
        <Box
            className="appBody"
            sx={{
                minHeight: "100vh",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                position: 'relative',
            }}
        >
            <Box sx={{ position: "fixed", right: "20px", bottom: "20px", zIndex: "99" }}>
                {(height > 1000) && <a href="#searchBlock"><ExpandCircleDownIcon sx={{ transform: "rotate(180deg)", fontSize: "65px", color: "#36cc95" }} /></a>}
            </Box>
            <Header2 />
            {screenWidth > 900 &&
                <Box sx={{ position: "absolute", right: "0", top: "80px", zIndex: "-1", maxWidth: { md: "450px", lg: "750px" }, overflow: "hidden" }}>
                    <img src={logoBg} alt="logoBg" width="100%" height="100%" style={{ transform: "rotate(-70deg)", opacity: "0.8", }} />
                </Box>}
            <Box sx={{ padding: { xs: "0 4%", md: "0 8%" } }}>
                <StartSearch />
                <div id="searchBlock" />
                <HomePage />
            </Box>
            <div id="FAQ" />
            <Box sx={{ padding: { xs: "0px 8% 0px 8%", md: "0px 0px 0px 8%" }, mt: "120px", width: { xs: "84%", md: "92%" } }}>
                <FaqBlock />
            </Box>
            <div id="AskQuestions" />
            <div id="Contacts" />
            <Box sx={{ height: "auto", mt: "100px" }}>
                <Footer />
            </Box>
        </Box >
    );
}

export default App;
