import * as React from "react";
import { Box } from "@mui/material";
// import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/use-redux";
// import styles from "./data-user-by-pin.module.scss";

const DataUserByPin = () => {
    const { isLoading: pinLoading, dataByPin } = useAppSelector(state => state.getByPinReducer)
    // const { t } = useTranslation();
    const scollRef = React.useRef<null | HTMLDivElement>(null);
    const executeScroll = () => scollRef.current?.scrollIntoView({ behavior: 'smooth' });
    React.useEffect(() => {
        if (dataByPin) {
            executeScroll();
        }
    }, [pinLoading, dataByPin]);
    return (
        <Box>
            <Box ref={scollRef}></Box>
        </Box >
    );
}

export default DataUserByPin;

