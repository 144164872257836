import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TolomButton } from '../../components'

import styles from "./start-search.module.scss"

const StartSearch = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ mt: { xs: "120px", lg: "180px" }, mb: "200px", }}>
            <Box sx={{ width: { xs: "100%", lg: "35%" }, display: "flex", flexDirection: "column", alignItems: { xs: "center", md: "flex-start" } }}>
                <Typography className={styles.title} sx={{ lineHeight: { xs: "60px", md: "90px" }, fontWeight: "500" }}>
                    {t("main.title.finesCheck1")}
                    <br />
                    {t("main.title.finesCheck2")}
                </Typography>
                <Typography className={styles.text} sx={{ fontFamily: "Montserrat!important", mt: { xs: "16px", md: "40px" }, textAlign: { xs: "center", md: "left" } }}>
                    {t("main.text.finesCheck")}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-end" }, mt: { xs: "25px", md: "70px" }, width: "100%" }}>
                    <TolomButton href='#searchBlock' text={t("startSearch")} style={{ padding: "10px 50px" }} />
                </Box>
            </Box>
        </Box>
    )
}

export default StartSearch