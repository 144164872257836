import {
    Box,
    Grid,
    Paper,
    Typography,
    Theme,
} from "@mui/material";
import { ModalResultSecurityCityByPin } from "../../index";
import { useTranslation } from "react-i18next";
import style from "./result-security-city-by-pin.module.scss"
import { BgProtocol } from "../../../redux/types/pin-types.types";

type Props = {
    data: BgProtocol;
    i: number;
}

const ResultSecurityCityByPinNewBg = ({ data, i }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <Box key={i} sx={{ display: "flex", gap: "8px" }}>
                <Paper
                    elevation={0}
                    sx={{
                        padding: "20px 10px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "15px",
                        marginBottom: "20px",
                        color: "#101828",
                        width: "100%",
                        borderRadius: "8px",
                        background: "#F9F9F9"
                    }}
                >
                    <Grid container spacing={2} key={i} columns={14}>
                        <Grid item xs={0.5}>
                            <Typography sx={{ fontWeight: "500" }}>{++i}{"."}</Typography>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            xs={14}
                            sx={gridsAdapting}
                        >
                            <Typography sx={fontAdapting}>
                                {t("allResults.output.paymentCode")}
                            </Typography>
                            <Typography
                                sx={fontDataAdapting}
                            >
                                {data.paymentCode}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            xs={14}
                            sx={gridsAdapting}
                        >
                            <Typography sx={{ color: "#ED5321", fontWeight: "500", fontSize: { xs: "14px", sm: "16px" } }} >
                                {t("allResults.output.paymentSum")}
                            </Typography>
                            <Typography sx={{ color: "#ED5321", fontWeight: "500", fontSize: { xs: "15px", sm: "16px" } }}>
                                {data.fineAmountToPay}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={14}
                            lg={7.5}
                            className={style.securityGridSelect}
                        >
                            <ModalResultSecurityCityByPin user={data} />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </>
    );
};

export default ResultSecurityCityByPinNewBg;

const gridsAdapting = (theme: Theme) => ({
    [theme.breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column!important",
        gap: "5px",
    },
    [theme.breakpoints.down("lg")]: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    [theme.breakpoints.down("sm")]: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
});

const fontAdapting = (theme: Theme) => ({
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
    },
});

const fontDataAdapting = (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
    },
});
