import { Typography, Box, Grid, Paper, Theme } from "@mui/material";
import {
    ModalResultErpnByNumber,
    PaymentModal,
    ResultSecurityCityByNumber,
} from "../../index";
import { useTranslation } from "react-i18next";
import style from "./result-erpn-by-number.module.scss"
import { ErpnProtocol, IPenaltiesPlate } from "../../../redux/types/plate-types.types";

interface IERNResultProps {
    plateData: IPenaltiesPlate;
    plateLoading: boolean;
}

const ResultErpnByNumber = ({ plateLoading, plateData }: IERNResultProps) => {
    const { t } = useTranslation();
    return (
        <>
            {plateLoading ? null : (
                <>
                    {plateData?.penalties?.data?.erpnProtocols?.length ? (
                        <Box>
                            <Typography sx={{ fontWeight: "500", fontSize: "22px", color: "#101828", mb: "30px", maxWidth: "780px" }} className={style.title}>
                                {t("allResults.titles.erpnFines")}
                            </Typography>
                            <>
                                {plateData?.penalties?.data?.erpnProtocols?.map((user: ErpnProtocol, i: number) => (
                                    <Box key={user.paymentCode + i}>
                                        <Box key={i} sx={{ display: "flex", gap: "8px" }}>
                                            <Paper
                                                elevation={0}
                                                sx={{
                                                    padding: "20px 10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "15px",
                                                    marginBottom: "20px",
                                                    color: "#101828",
                                                    width: "100%",
                                                    borderRadius: "8px",
                                                    background: "#F9F9F9"
                                                }}
                                            >
                                                <Grid container spacing={2} columns={14}>
                                                    <Grid item xs={0.5}>
                                                        <Typography sx={{ fontWeight: "500" }}>{i + 1}{"."}</Typography>
                                                    </Grid>
                                                    <Grid item md={5.5} xs={14} sx={gridAdapting}>
                                                        <Typography sx={fontAdapting}>
                                                            <b>{t("allResults.output.paymentCode")}</b>
                                                        </Typography>
                                                        <Typography sx={fontAdapting}>
                                                            {user.paymentCode}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={8} xs={14} sx={gridAdapting}>
                                                        <Typography sx={fontAdapting}>
                                                            <b>{t("allResults.output.protocolNumber")}</b>
                                                        </Typography>
                                                        <Typography sx={fontAdapting}>
                                                            {user.protocolNumber}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={7} xs={14} sx={gridAdapting}>
                                                        <Typography sx={{ color: "#ED5321", fontWeight: "500" }}>
                                                            {t("allResults.output.paymentSum")}{" "}
                                                            {user.fineAmountToPay}
                                                            {user.penaltyAmount ? (
                                                                <>
                                                                    ({user.fineAmount}{" "}
                                                                    {t("allResults.output.fines")}
                                                                    {user.penaltyAmount}{" "}
                                                                    {t("allResults.output.penya")})
                                                                </>
                                                            ) : null}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={14} md={7} className={style.numbergridSelect}>
                                                        <ModalResultErpnByNumber user={user} />
                                                        <PaymentModal paymentCode={user.paymentCode} />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Box>
                                    </Box>
                                ))}
                            </>
                        </Box>
                    ) : (
                        <Typography sx={{ fontWeight: "500", fontSize: "22px", color: "#36cc95", mt: "30px", maxWidth: "780px" }} className={style.title}>
                            {t("allResults.titles.finesERPNnotFound")}
                        </Typography>
                    )}
                    {plateData?.penalties?.data?.bgProtocols?.length ?
                        <>
                            <Typography sx={{ fontWeight: "500", fontSize: "22px", color: "#101828", margin: "30px 0", maxWidth: "780px" }} className={style.title}>
                                {t("allResults.titles.safetyCityFines")}
                            </Typography>
                            <ResultSecurityCityByNumber violationsBg={plateData?.penalties?.data?.bgProtocols} />
                        </>
                        : <Typography sx={{ fontWeight: "500", fontSize: "22px", color: "#36cc95", mt: "30px", maxWidth: "780px" }} className={style.title}>
                            {t("allResults.titles.safetyCityFinesNotFound")}
                        </Typography>
                    }
                </>
            )}
        </>
    );
};

export default ResultErpnByNumber;

const gridAdapting = (theme: Theme) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
    },
    [theme.breakpoints.down("md")]: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
    },
});

const fontAdapting = (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
    },
});
