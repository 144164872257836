import { useEffect, useRef, useState } from "react";
import { Box, Paper, Typography, Button } from "@mui/material";
import {
    CheckByNumber,
    CheckByInn,
    MainResultByNumber,
    MainResultByPin,
} from "../features/index";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../hooks/use-redux";
import passportImg from "../assets/images/passportImg.webp";
import plateImg from "../assets/images/plateImg.webp";
import { InfoText } from "../components";
import CheckByForeignNumber from "../features/foreign-number/check-by-foreign-number/check-by-foreign-number";
import MainResultByForeignPlate from "../features/foreign-number/main-result/main-result";
import { resetForeignPlateData } from "../redux/reducer/get-by-foreign-plate";
import styles from "./home-page.module.scss";
import "../features/styleee.scss"

const HomePage = () => {
    const reRef = useRef<any>();
    const { t } = useTranslation();
    const [searchVariant, setSearchVariant] = useState<string>("PLATE");
    const dispatch = useAppDispatch()

    const [reCaptcha, setReCaptcha] = useState(true);
    const [token, setToken] = useState<string>("");

    const {
        isLoading: plateLoading,
        dataByPlate: plateData,
        isError: plateError,
        reloadCaptcha: captchaPlate
    } = useAppSelector(state => state.getByPlateReducer)
    const {
        dataByPin: pinData,
        reloadCaptcha: captchaPin,
        isError: pinError,
        isLoading: pinLoading
    } = useAppSelector(state => state.getByPinReducer)
    const {
        isLoading: foreignPlateLoading,
        dataByForeignPlate: foreignPlateData,
        isError: foreignPlateError,
        reloadCaptcha: foreignCaptchaPlate
    } = useAppSelector(state => state.getByForeignPlateReducer)

    const scollRef = useRef<null | HTMLDivElement>(null);
    const executeScroll = () => scollRef.current?.scrollIntoView({ behavior: 'smooth' });
    useEffect(() => {
        if (pinData !== undefined || plateData !== undefined) {
            executeScroll();
        }
    }, [pinData, pinLoading, plateData, plateLoading]);

    const captchaChange = (token: string | null) => {
        if (token !== null) {
            setToken(token);
        }
        setReCaptcha(false)
    }
    if (!captchaPlate || !captchaPin || !foreignCaptchaPlate) {
        reRef.current?.reset();
    }

    const changeVarToPin = () => {
        setSearchVariant("PIN");
        dispatch(resetForeignPlateData())
    }
    const changeVarToPlate = () => {
        setSearchVariant("PLATE");
        dispatch(resetForeignPlateData())
    }
    const changeVarToForeignPlate = () => {
        setSearchVariant("FOREIGN");
        dispatch(resetForeignPlateData())
    }

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                pt: "30px",
            }}
        >
            <Box sx={{ display: "flex", gap: "30px", width: "100%", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: { xs: "center", md: "flex-start" } }}>
                <Paper sx={{
                    padding: { xs: "0", md: "40px 52px" }, width: { xs: "100%", md: "50%" }, color: "#101828", background: { xs: "none", md: "white" },
                    boxShadow: { xs: "none", md: "0px 4px 4px rgba(16, 24, 40, 0.25)" }
                }}>
                    <Typography className={styles.title} sx={{ fontWeight: "500" }}>
                        {t("main.title.title")}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-around", mt: "80px", mb: { xs: "24px", md: "40px" }, flexDirection: { xs: "column", lg: "row" }, gap: "8px" }}>
                        <Button onClick={changeVarToPlate} sx={{
                            fontWeight: "500", fontSize: "16px", lineHeight: "28px",
                            width: { xs: "100%", lg: "45%" }, padding: "12px 10px",
                            borderRadius: "12px", backgroundColor: searchVariant === 'PLATE' ? "#36cc95" : "#F2F2F2",
                            color: searchVariant === 'PLATE' ? "white" : "#101828", "&:hover": { backgroundColor: "#36cc95", color: "white" }
                        }} variant="contained">
                            {t("main.title.finesCarNumber")}<br />{t("main.title.number")}
                        </Button>
                        <Button onClick={changeVarToPin} sx={{
                            fontWeight: "500", fontSize: "16px", lineHeight: "28px",
                            width: { xs: "100%", lg: "45%" }, padding: "12px 10px",
                            borderRadius: "12px", backgroundColor: searchVariant === 'PIN' ? "#36cc95" : "#F2F2F2",
                            color: searchVariant === 'PIN' ? "white" : "#101828", "&:hover": { backgroundColor: "#36cc95", color: "white" }
                        }} variant="contained">
                            {t("main.title.finesPIN")}<br />{t("main.title.inn")}
                        </Button>
                        <Button onClick={changeVarToForeignPlate} sx={{
                            fontWeight: "500", fontSize: "16px", lineHeight: "28px",
                            width: { xs: "100%", lg: "45%" }, padding: "12px 10px",
                            borderRadius: "12px", backgroundColor: searchVariant === 'FOREIGN' ? "#36cc95" : "#F2F2F2",
                            color: searchVariant === 'FOREIGN' ? "white" : "#101828", "&:hover": { backgroundColor: "#36cc95", color: "white" }
                        }} variant="contained">
                            {t("main.title.foreign")}
                        </Button>
                    </Box>
                    {searchVariant === 'PIN' &&
                        <CheckByInn captcha={reCaptcha} setCaptcha={setReCaptcha}
                            captchaPlate={captchaPlate} captchaPin={captchaPin} foreignPaptchaPlate={foreignCaptchaPlate}
                            captchaChange={captchaChange} reRef={reRef} token={token} />
                    }
                    {searchVariant === 'PLATE' &&
                        <CheckByNumber captcha={reCaptcha} setCaptcha={setReCaptcha}
                            captchaPlate={captchaPlate} captchaPin={captchaPin} foreignPaptchaPlate={foreignCaptchaPlate}
                            captchaChange={captchaChange} reRef={reRef} token={token} />
                    }
                    {searchVariant === 'FOREIGN' &&
                        <CheckByForeignNumber captcha={reCaptcha} setCaptcha={setReCaptcha}
                            captchaPlate={captchaPlate} captchaPin={captchaPin} foreignPaptchaPlate={foreignCaptchaPlate}
                            captchaChange={captchaChange} reRef={reRef} token={token} />
                    }
                </Paper>
                <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "80%", md: "50%" }, marginLeft: { xs: "0", md: "50px" } }}>
                    {searchVariant === 'PIN' &&
                        <InfoText image={passportImg} title={t("whatPin")}
                            text1={t("pinIs1")}
                            text2={t("pinIs2")} />
                    }
                    {searchVariant === 'PLATE' &&
                        <InfoText image={plateImg} title={t("whatPlate")}
                            text1={t("plateIs1")}
                            text2={t("plateIs2")} />
                    }
                    {searchVariant === 'FOREIGN' &&
                        <InfoText image={plateImg} title={t("whatPlate")}
                            text1={t("plateIs1")}
                            text2={t("plateIs2")} />
                    }
                </Box>
            </Box>
            <Box ref={scollRef}></Box>
            {(plateLoading || pinLoading || foreignPlateLoading) && (
                <Box
                    sx={{
                        textAlign: "center",
                        position: "relative",
                        marginTop: "80px"
                    }}
                >
                    <div className="showbox">
                        <div className="loader">
                            <svg className="circular" viewBox="25 25 50 50">
                                <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
                            </svg>
                        </div>
                    </div>
                </Box>
            )}
            {
                (searchVariant === "PLATE" && plateData !== undefined && !plateLoading) &&
                <MainResultByNumber plateLoading={plateLoading} plateData={plateData} plateError={plateError} />
            }
            {
                (searchVariant === "PIN" && pinData !== undefined && !pinLoading) &&
                <MainResultByPin pinLoading={pinLoading} />
            }
            {
                (searchVariant === "FOREIGN" && foreignPlateData !== undefined && !foreignPlateLoading && !foreignPlateError) &&
                <MainResultByForeignPlate foreignPlateLoading={foreignPlateLoading} foreignPlateData={foreignPlateData} />
            }
            {
                (plateError || pinError || foreignPlateError) ?
                    <Box sx={{ mt: "4rem", width: "100%" }}>
                        <Paper elevation={5} sx={{ borderRadius: "12px", padding: "15px 0" }}>
                            <Typography variant={"h5"} sx={{ textAlign: "center", color: "black", textShadow: "1px 2px #a1a09f" }}>
                                {foreignPlateError} {pinError} {plateError}
                            </Typography>
                        </Paper>
                    </Box>
                    : null
            }
        </Box >
    );
};

export default HomePage;
