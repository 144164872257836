export { default as Header2 } from "./header/header2";
export { default as MainResultByNumber } from "./number/main-result/main-result";
export { default as MainResultByPin } from "./inn/main-result/main-result";
export { default as ResultErpnByNumber } from "./number/result-erpn-by-number/result-erpn-by-number";
export { default as ModalResultErpnByNumber } from "./number/result-erpn-by-number/modal/modal-result-erpn-by-number";
export { default as ResultErpnByPin } from "./inn/result-erpn-by-pin/result-erpn-by-pin";
export { default as ModalResultErpnByPin } from "./inn/result-erpn-by-pin/modal/modal-result-erpn-by-pin";
export { default as ModalResultSecurityCityByNumber } from "./number/result-security-city-by-number/modal/modal-result-security-city-by-number";
export { default as ModalResultSecurityCityByPin } from "./inn/result-security-city-by-pin/modal/modal-result-security-city-by-pin";
export { default as DataUserByNumber } from "./number/data-user-by-number/data-user-by-number";
export { default as TotalAmountAllFineByPin } from "./inn/total-amount-all-fines-by-pin/total-amount-all-fines-by-pin";
export { default as TotalAmountAllFineByNumber } from "./number/total-amount-all-fine-by-number/total-amount-all-fine-by-number";
export { default as Texts } from "./main-page-text/text";
export { default as CheckByInn } from "./inn/check-by-inn/check-by-inn";
export { default as CheckByNumber } from "./number/check-by-number/check-by-number";
export { default as LangChange } from "./lang-change/lang-change";
export { default as Footer } from "./footer/footer";
export { default as DataUserByPin } from "./inn/data-user-by-pin/data-user-by-pin";
export { default as PaymentModal } from "./number/result-erpn-by-number/modal/payment-modal";
export { default as Faq } from "./faq/faq";
export { default as TechWork } from "./tech-work/tech-work";
export { default as StartSearch } from "./start-search/start-search";
export { default as ResultSecurityCityByNumber } from "./number/result-security-city-by-number/result-security-city-by-number";
export { default as ResultSecurityCityByPinNewBg } from "./inn/result-security-city-by-pin/result-security-city-by-pinNewBg";
export { default as DataUserByNumberOther } from "./number/data-user-by-number/data-user-by-number-other";
