import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IPenaltiesInn, IRequest } from "../types/pin-types.types";

interface IDataByPin {
  dataByPin: IPenaltiesInn | undefined;
  isLoading: boolean;
  isError: string;
  reloadCaptcha: boolean;
}

const initialState: IDataByPin = {
  dataByPin: undefined,
  isLoading: false,
  isError: "",
  reloadCaptcha: true,
};

export const getByPinAsync = createAsyncThunk(
  "get-by-pin/getByPin",
  async function (request: IRequest, thunkAPI) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/penalty/by-pin`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(request),
        }
      );
      if (!response.ok) {
        throw new Error(`${response.status}`);
      }
      return await response.json();
    } catch (error: any) {
      if (error.message === "500") {
        return thunkAPI.rejectWithValue("Сервис временно недоступен!");
      } else {
        return thunkAPI.rejectWithValue("Подключитесь к интернету!");
      }
    }
  }
);

export const getByPinReducer = createSlice({
  name: "get-by-pin",
  initialState,
  reducers: {
    resetPinData: (state) => {
      state.dataByPin = undefined;
    },
  },
  extraReducers: {
    [getByPinAsync.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = "";
      state.reloadCaptcha = false;
    },
    [getByPinAsync.fulfilled.type]: (state, action: PayloadAction<IPenaltiesInn>) => {
      state.dataByPin = action.payload;
      state.isLoading = false;
      state.isError = "";
      state.reloadCaptcha = true;
    },
    [getByPinAsync.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isError = action.payload;
      state.reloadCaptcha = true;
    },
  },
});

export const { resetPinData } = getByPinReducer.actions;
export default getByPinReducer.reducer;
