import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IPenaltiesPlate, IRequestPlate } from "../types/plate-types.types";

interface IDataByPlate {
  dataByPlate: IPenaltiesPlate | undefined;
  isLoading: boolean;
  isError: string;
  reloadCaptcha: boolean;
}

const initialState: IDataByPlate = {
  dataByPlate: undefined,
  isLoading: false,
  isError: "",
  reloadCaptcha: true,
};

export const getByPlateAsync = createAsyncThunk(
  "get-by-plate/getByPlate",
  async function (request: IRequestPlate, thunkAPI) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/penalty/by-plate`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(request),
        }
      );
      if (!response.ok) {
        throw new Error(`${response.status}`);
      }
      return await response.json();
    } catch (error: any) {
      if (error.message === "500") {
        return thunkAPI.rejectWithValue("Сервис временно недоступен!");
      } else {
        return thunkAPI.rejectWithValue("Подключитесь к интернету!");
      }
    }
  }
);

export const getByPlateReducer = createSlice({
  name: "get-by-plate",
  initialState,
  reducers: {
    resetPlateData: (state) => {
      state.dataByPlate = undefined;
    },
  },
  extraReducers: {
    [getByPlateAsync.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = "";
      state.reloadCaptcha = false;
    },
    [getByPlateAsync.fulfilled.type]: (
      state,
      action: PayloadAction<IPenaltiesPlate>
    ) => {
      state.dataByPlate = action.payload;
      state.isLoading = false;
      state.isError = "";
      state.reloadCaptcha = true;
    },
    [getByPlateAsync.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isError = action.payload;
      state.reloadCaptcha = true;
    },
  },
});

export const { resetPlateData } = getByPlateReducer.actions;
export default getByPlateReducer.reducer;
