import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper } from '@mui/material'
import { LiveHelp, CheckCircle, ExpandMore } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import TolomButton from '../button/tolom-button';
import { useTranslation } from 'react-i18next';

interface IProps {
    question?: string;
    answear?: string;
    varr: string;
    expanded: string | false;
    handleChangeAccordion: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const FaqCard = (props: IProps) => {
    const [textState, setTextState] = useState<boolean>(false);
    const { t } = useTranslation();
    const screenWidth = window.innerWidth;

    const handleChange = () => {
        setTextState((prev) => !prev)
    }
    return (
        <>
            {screenWidth > 900
                ?
                <Paper sx={{
                    width: "290px", height: "480px", display: "flex", flexDirection: "column",
                    alignItems: "center", backgroundColor: "#F9F9F9"
                }}>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "24px 20px 0 0" }}>
                        {!textState
                            ? <LiveHelp sx={{ color: "#101828", fontSize: "26px", mb: "18px" }} />
                            : <CheckCircle sx={{ color: "#36cc95", fontSize: "26px", mb: "18px" }} />
                        }
                    </Box>
                    {!textState ?
                        <Typography sx={{ padding: "25px 20px", fontSize: "20px" }}>
                            {props.question}
                        </Typography>
                        :
                        <Typography sx={{ padding: "25px 20px", fontSize: "14px", fontWeight: "500" }}>
                            {props.answear}
                        </Typography>}
                    <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                        {textState
                            ? <TolomButton text={t("hideAnswear")} style={{ width: "80%", marginBottom: "16px", padding: "12px 24px" }} onClick={handleChange} />
                            : <TolomButton text={t("answear")} style={{ width: "80%", marginBottom: "16px", padding: "12px 24px" }} onClick={handleChange} />
                        }
                    </Box>
                </Paper>
                :
                <Accordion expanded={props.expanded === props.varr} onChange={props.handleChangeAccordion(props.varr)}
                    sx={{ marginBottom: "24px!important", background: "#F9F9F9", boxShadow: "none", borderRadius: "8px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`${props.varr}-content`}
                        id={`${props.varr}-header`}
                    >
                        <Typography>{props.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {props.answear}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            }
        </>
    )
}

export default FaqCard