import ErrorRoundedIcon from '@mui/icons-material/Error';
import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./parking.module.scss";

interface IProps {
    adress: string;
}

export const Parking = ({ adress }: IProps) => {
    const { t } = useTranslation();
    return (
        <Paper elevation={3}
            sx={{
                padding: "24px 12px",
                borderRadius: "4px",
                backgroundColor: "#fff",
                mt: "40px",
                mb: "2rem"
            }}>
            <ErrorRoundedIcon className={styles.errorIcon} sx={{ fontSize: "30px" }} />
            <Typography fontWeight="500" className={styles.text}>
                {t("allResults.output.parkingAdress")} <span style={{ color: "#ed5321" }}>{t("allResults.output.parkingAdressRed")}</span>{t("allResults.output.parkingAdressEnd")}:
            </Typography>
            <Typography fontWeight="500" className={styles.text}>
                {adress}
            </Typography>
        </Paper>
    )
}
