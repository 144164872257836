// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-result-erpn-by-number_close__7ZDIw {\n  position: absolute !important;\n  top: 1px;\n  right: 0;\n}\n\n.modal-result-erpn-by-number_buttonStyle__YEBUO {\n  background-color: #408af4 !important;\n  border-radius: 20px !important;\n  box-shadow: 2px 2px 8px 0 rgba(50, 50, 50, 0.5) !important;\n  color: white !important;\n}", "",{"version":3,"sources":["webpack://./src/features/foreign-number/main-result/modal/modal-result-erpn-by-number.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,QAAA;EACA,QAAA;AACF;;AACA;EACE,oCAAA;EACA,8BAAA;EACA,0DAAA;EACA,uBAAA;AAEF","sourcesContent":[".close {\r\n  position: absolute !important;\r\n  top: 1px;\r\n  right: 0;\r\n}\r\n.buttonStyle {\r\n  background-color: #408af4 !important;\r\n  border-radius: 20px !important;\r\n  box-shadow: 2px 2px 8px 0 rgb(50 50 50 / 50%) !important;\r\n  color: white !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "modal-result-erpn-by-number_close__7ZDIw",
	"buttonStyle": "modal-result-erpn-by-number_buttonStyle__YEBUO"
};
export default ___CSS_LOADER_EXPORT___;
