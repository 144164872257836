import {
    ResultErpnByPin,
    ResultSecurityCityByPinNewBg,
    TotalAmountAllFineByPin,
} from "../../index";
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/use-redux";

import styles from "./mainResult.module.scss"

interface IPinMainResultProps {
    pinLoading: boolean;
}

const MainResultByPin = ({ pinLoading }: IPinMainResultProps) => {
    const { dataByPin: pinData } = useAppSelector(state => state.getByPinReducer)

    const fail = pinData?.penalties.message === "FAIL";
    const { t } = useTranslation();

    return (
        <>
            {(pinData?.penalties?.data?.erpnProtocols !== null) &&
                <Box sx={{ paddingTop: "4rem", width: "100%" }}>
                    {!pinLoading &&
                        <>
                            {pinData &&
                                <>
                                    <TotalAmountAllFineByPin pinData={pinData} />
                                    {pinData?.penalties?.success && pinData?.penalties?.data ? (
                                        <ResultErpnByPin pinData={pinData} pinLoading={pinLoading} />
                                    ) : (
                                        <Typography sx={{ fontWeight: "500", fontSize: "22px", color: "#36cc95", mt: "30px", maxWidth: "780px" }} className={styles.title}>
                                            {t("allResults.titles.finesERPNnotFound")}
                                        </Typography>
                                    )}
                                    {pinData?.penalties?.data?.bgProtocols?.length ? (
                                        <>
                                            <Typography sx={{ fontWeight: "500", fontSize: "22px", color: "#101828", margin: "30px 0", maxWidth: "780px" }} className={styles.title}>
                                                {t("allResults.titles.safetyCityFines")}
                                            </Typography>
                                            {pinData?.penalties?.data?.bgProtocols?.map((item, i: number) => (
                                                <Box key={++i}>
                                                    <ResultSecurityCityByPinNewBg key={item.paymentCode} data={item} i={i} />
                                                </Box>
                                            ))
                                            }
                                        </>
                                    ) : <Typography sx={{ fontWeight: "500", fontSize: "22px", color: "#36cc95", mt: "30px", maxWidth: "780px" }} className={styles.title}>
                                        {t("allResults.titles.safetyCityFinesNotFound")}
                                    </Typography>}
                                    {
                                        pinData?.penalties?.data?.bgProtocols && !fail && (
                                            pinData?.penalties?.data?.bgProtocols?.map((item, i: number) => (
                                                <Box key={i}>
                                                    {
                                                        fail && (
                                                            <Paper
                                                                elevation={3}
                                                                sx={{
                                                                    textAlign: "start",
                                                                    padding: "10px",
                                                                    borderRadius: "12px",
                                                                    width: "100%",
                                                                    display: "grid",
                                                                    mt: "30px",
                                                                    marginBottom: "10px",
                                                                }}
                                                            >
                                                                <Typography sx={{
                                                                    fontSize: "24px",
                                                                    fontWeight: "500", color: "red"
                                                                }}>
                                                                    {++i}{")    "}{t("allResults.titles.failedRequest")}
                                                                </Typography>
                                                            </Paper>
                                                        )
                                                    }
                                                </Box>
                                            ))
                                        )
                                    }
                                </>
                            }
                        </>}
                </Box >
            }
        </>
    );
};

export default MainResultByPin;
