// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-result-security-city-by-number_close__qAP80 {\n  position: absolute !important;\n  top: 1px;\n  right: 0;\n}\n\n.modal-result-security-city-by-number_buttonStyle__VLUeJ {\n  background-color: #408af4 !important;\n  border-radius: 20px !important;\n  box-shadow: 2px 2px 8px 0 rgba(50, 50, 50, 0.5) !important;\n  color: white !important;\n}", "",{"version":3,"sources":["webpack://./src/features/number/result-security-city-by-number/modal/modal-result-security-city-by-number.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,QAAA;EACA,QAAA;AACF;;AACA;EACE,oCAAA;EACA,8BAAA;EACA,0DAAA;EACA,uBAAA;AAEF","sourcesContent":[".close {\n  position: absolute !important;\n  top: 1px;\n  right: 0;\n}\n.buttonStyle {\n  background-color: #408af4 !important;\n  border-radius: 20px !important;\n  box-shadow: 2px 2px 8px 0 rgb(50 50 50 / 50%) !important;\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "modal-result-security-city-by-number_close__qAP80",
	"buttonStyle": "modal-result-security-city-by-number_buttonStyle__VLUeJ"
};
export default ___CSS_LOADER_EXPORT___;
