import React from "react";
import {
    Box,
    Modal,
    Fade,
    Typography,
    IconButton,
    Theme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import style from "../result-security-city-by-pin.module.scss";
import { TolomButton } from "../../../../components";
import { BgProtocol } from "../../../../redux/types/pin-types.types";

type Props = {
    user: BgProtocol;
};

const ModalResultSecurityCityByPin = ({ user }: Props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <TolomButton onClick={handleOpen} text={t("allResults.output.erpnModal")} style={{ padding: "10px 15px" }} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box sx={modalAdapting}>
                        <IconButton
                            className={style.close}
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography>
                            <b>{t("allResults.output.paymentCode")}: </b>
                            {user.paymentCode}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.deliveryStatus")}: </b>
                            {!user.deliveryDate
                                ? t("allResults.output.notDelivered")
                                : t("allResults.output.deliveredByDate") +
                                user.deliveryDate}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.protocolNumber")}: </b>
                            {user.protocolNumber}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.finesLocation")}: </b>
                            {user.violationPlace}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.article")}: </b>
                            {user.article}.
                        </Typography>
                        {user.part &&
                            <Typography
                                id="transition-modal-description"
                                sx={{
                                    mt: 2,
                                    // color: user.paymentStatus === "PENDING" ? "#ED5321" : "#32CD32",
                                }}
                            >
                                <b>{t("allResults.output.part")}:</b> {user.part}
                                {/* {user.part} */}
                            </Typography>
                        }
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.offense")}: </b>
                            {user.violationTitle}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default ModalResultSecurityCityByPin;

const modalAdapting = (theme: Theme) => ({
    [theme.breakpoints.up(800)]: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        padding: "1rem",
        backgroundColor: "#f6f6f6",
        borderRadius: "7px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    },
    [theme.breakpoints.down(800)]: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "85%",
        padding: "1rem",
        backgroundColor: "#f6f6f6",
        borderRadius: "7px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    },
});
