import { Accordion, AccordionDetails, AccordionSummary, Box, Theme, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

import React from 'react'

const Faq = () => {
    const { t } = useTranslation();
    return (
        <Box mb={3}>
            <Box>
                <h3>{t("faq.title")}</h3>
            </Box>
            <Box>
                <Accordion sx={accordionAdaptive}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                    >
                        <Typography sx={typographySize}>{t("faq.questions.first")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={typographyTextSize}>
                            {t("faq.answers.first.part1")}
                            <br />
                            <br />
                            {t("faq.answers.first.part2")}
                            <br />
                            {t("faq.answers.first.part3")}
                            <br />
                            {t("faq.answers.first.part4")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionAdaptive}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                    >
                        <Typography sx={typographySize}>{t("faq.questions.second")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={typographyTextSize}>
                            {t("faq.answers.second")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionAdaptive}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                    >
                        <Typography sx={typographySize}>{t("faq.questions.third")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={typographyTextSize}>
                            {t("faq.answers.third")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionAdaptive}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                    >
                        <Typography sx={typographySize}>{t("faq.questions.fourth")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={typographyTextSize}>
                            {t("faq.answers.fourth")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionAdaptive}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                    >
                        <Typography sx={typographySize}>{t("faq.questions.fifth")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={typographyTextSize}>
                            {t("faq.answers.fifth")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    )
}

export default Faq

const accordionAdaptive = (theme: Theme) => ({
    background: "rgba(255, 255, 255, 0.5)",
    width: "90%",
    [theme.breakpoints.down("md")]: {
        width: "100%"
    }
})

const typographySize = (theme: Theme) => ({
    fontSize: "14px",
})

const typographyTextSize = (theme: Theme) => ({
    fontSize: "13px",
})