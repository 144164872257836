import React, { useEffect } from "react";
import { TextField, Box, Grid, Theme, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/use-redux";
import { getByPinAsync, resetPinData } from "../../../redux/reducer/get-by-pin";
import { SubmitHandler, useForm } from "react-hook-form";
import { TolomButton2 } from "../../../components";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./check-by-inn.module.scss"

interface IProps {
    captcha: boolean;
    setCaptcha: React.Dispatch<React.SetStateAction<boolean>>;
    captchaChange: (token: string | null) => void;
    captchaPlate: boolean;
    captchaPin: boolean;
    foreignPaptchaPlate: boolean;
    reRef: React.MutableRefObject<any>;
    captchaSize?: boolean;
    token: string | null;
}

interface IPin {
    pinNumber: string;
}

const CheckByInn = ({ captcha, setCaptcha, captchaChange, captchaPlate, captchaPin, reRef, token, foreignPaptchaPlate }: IProps) => {
    const { t } = useTranslation();
    const { isLoading: pinLoading } = useAppSelector(state => state.getByPinReducer)
    const dispatch = useAppDispatch()
    const { register, formState: { errors }, handleSubmit } = useForm<IPin>({ mode: "onBlur" });

    const handleSearchByPin: SubmitHandler<IPin> = async (data) => {
        const request = {
            token: token,
            pin: data.pinNumber
        }
        if (!captcha) {
            dispatch(getByPinAsync(request))
            setCaptcha(true)
        }
    }

    useEffect(() => {
        dispatch(resetPinData())
    }, [dispatch])

    return (
        <Grid item xs={12}>
            <Typography className={styles.text} sx={{ fontWeight: "400", mb: { xs: "24px", md: "56px" } }}>
                {t("main.text.finesPIN")}
            </Typography>
            <form onSubmit={handleSubmit(handleSearchByPin)}>
                <Box>
                    <Grid container spacing={0} sx={mainGrid}>
                        <Grid item xs={12}>
                            <h5 className={styles.title}>
                                {t("main.input.pin")}
                            </h5>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                            <TextField
                                id="outlined-basic"
                                label={t("main.input.pinInn")}
                                variant="outlined"
                                fullWidth={true}
                                type="number"
                                InputLabelProps={{ required: false }}
                                {...register("pinNumber", {
                                    required: t("allResults.validation.enterPin"),
                                    minLength: {
                                        value: 14,
                                        message: t("allResults.validation.minSizePin")
                                    },
                                    maxLength: {
                                        value: 14,
                                        message: t("allResults.validation.maxSizePin")
                                    }
                                })}
                                sx={InputAdapting}
                            />
                            {errors?.pinNumber && <Box sx={{ position: "absolute", color: "red", fontSize: "12px" }}>{errors?.pinNumber?.message}</Box>}
                        </Grid>
                        <Grid item xs={12} lg={6} className={styles.captchaPlate} sx={{ paddingTop: { xs: "10px", md: "20px" } }}>
                            {captchaPlate && captchaPin && foreignPaptchaPlate ?
                                <Box>
                                    <ReCAPTCHA
                                        sitekey="6LcptCIjAAAAAAWfi-MN_0ceGjgzTcz90wOdDwJN"
                                        onChange={captchaChange}
                                        ref={reRef}
                                        size="normal"
                                        className={styles.captchaSize}
                                    />
                                </Box>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12} xl={5} sx={{ paddingTop: "20px" }}>
                            {!pinLoading ? (
                                <Grid item xs={12}>
                                    <TolomButton2 text={t("main.button.check")}
                                        fullWidth={true}
                                        type="submit"
                                        style={{ padding: "12px 24px", color: "white" }}
                                        disabled={captcha} />
                                </Grid>
                            ) : (
                                <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                                    <LoadingButton
                                        loading
                                        variant="contained"
                                        fullWidth={true}
                                        sx={{ borderRadius: "8px", background: "#101828", color: "white", fontWeight: "bold" }}
                                        loadingPosition="center"
                                    >
                                        {t("main.button.loading")}
                                    </LoadingButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Grid>
    );
};

export default CheckByInn;
const InputAdapting = (theme: Theme) => ({
    background: "rgba(54, 179, 166, 0.1)",
    [theme.breakpoints.up(2500)]: {
        "& .MuiInputLabel-root": {
            fontSize: "22px",
        },
        "& .MuiInputBase-root": {
            height: "80px",
        },
        "& .MuiOutlinedInput-input": {
            fontSize: "32px",
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "WebkitAppearance": "none",
            },
        },
    },
    [theme.breakpoints.down(2500)]: {
        "& .MuiInputBase-root": {
            height: "56px",
        },
        "& .MuiOutlinedInput-input": {
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "WebkitAppearance": "none",
            },
        }
    },
});

const mainGrid = (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
        justifyContent: "center",
    }
})