import { useEffect } from "react";
import { ModalResultSecurityCityByNumber } from "../../index";
import { Grid, Box, Paper, Typography, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BgProtocol } from "../../../redux/types/plate-types.types";
import style from "./result-security-city-by-number.module.scss"

interface IBGResultProps {
    violationsBg: BgProtocol[];
}
const ResultSecurityCityByNumber = ({ violationsBg }: IBGResultProps) => {
    useEffect(() => {
        const allFines: BgProtocol[] = [];

        if (violationsBg) {
            allFines.push(...violationsBg);
        }

    }, [violationsBg])
    const { t } = useTranslation();
    return (
        <>
            {violationsBg?.map((item: BgProtocol, i: number) => {
                return (
                    <Box key={i} sx={{ display: "flex", gap: "8px" }}>
                        <Paper
                            elevation={0}
                            sx={{
                                padding: "20px 10px",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "15px",
                                marginBottom: "20px",
                                color: "#101828",
                                width: "100%",
                                borderRadius: "8px",
                                background: "#F9F9F9"
                            }}
                        >
                            <Grid container spacing={2} columns={14}>
                                <Grid item xs={0.5}>
                                    <Typography sx={{ fontWeight: "500" }}>{i + 1}{"."}</Typography>
                                </Grid>
                                <Grid item lg={3} xs={14} sx={gridAdapting}>
                                    <b>{t("allResults.output.protocolNumber")}</b>
                                    <Box>{item.protocolNumber}</Box>
                                </Grid>
                                <Grid item lg={4} xs={14} sx={gridAdapting}>
                                    <Typography sx={{ color: "#ED5321" }}>
                                        <b>{t("allResults.output.paymentSum")}</b>
                                    </Typography>
                                    <Typography sx={{ color: "#ED5321" }}>
                                        {item.fineAmountToPay}
                                    </Typography>
                                </Grid>
                                <Grid item lg={2.5} xs={14} sx={gridAdapting}>
                                    <b>{t("allResults.output.paymentCode")} </b>
                                    {item.paymentCode}
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    xs={14}
                                    className={style.securityGridModal}
                                >
                                    <ModalResultSecurityCityByNumber item={item} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                )
            })}
        </>
    );
};

export default ResultSecurityCityByNumber;

const gridAdapting = (theme: Theme) => ({
    [theme.breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
    },
    [theme.breakpoints.down("lg")]: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
    },
});
