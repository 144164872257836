import { Box, Grid, Paper, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IForeignPlate } from "../../../redux/types/foreign-plate-types.types";
import ErrorRoundedIcon from '@mui/icons-material/Error';
import ModalResultByForeignNumber from "./modal/modal-result-by-foreign-number";
import style from "./main-result.module.scss"

interface IPlateResProps {
    foreignPlateLoading: boolean;
    foreignPlateData: IForeignPlate[];
}

const MainResultByForeignPlate = ({ foreignPlateData }: IPlateResProps) => {
    const { t } = useTranslation();
    const screenWidth = window.innerWidth;
    return (
        <Box sx={{ paddingTop: "4rem", width: "100%" }}>
            {foreignPlateData !== null
                &&
                <>
                    <Box sx={{ behavior: "smooth", display: "flex", justifyContent: "space-between" }}>
                        <h3 className={style.title}>
                            {t("allResults.titles.result")}
                        </h3>
                        {screenWidth < 900 &&
                            <ErrorRoundedIcon className={style.errorIcon} />
                        }
                    </Box>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: "24px 12px",
                            borderRadius: "8px",
                            display: "flex",
                            backgroundColor: "#F9F9F9",
                            margin: "40px 0"
                        }}
                    >
                        <Box>
                            <Typography fontWeight="500" className={style.text}>
                                {t("allResults.output.finesNumber")}
                            </Typography>
                        </Box>
                        <Box sx={{ color: "#ED5321", marginLeft: "5%" }}>
                            <Typography fontWeight="500" className={style.text}>
                                {foreignPlateData.length}
                            </Typography>
                        </Box>
                    </Paper>
                    {foreignPlateData?.map((item, index) => (
                        <Box key={index} sx={{ display: "flex", gap: "8px" }}>
                            <Paper
                                elevation={0}
                                sx={{
                                    padding: "20px 10px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "15px",
                                    marginBottom: "20px",
                                    color: "#101828",
                                    width: "100%",
                                    borderRadius: "8px",
                                    background: "#F9F9F9"
                                }}
                            >
                                <Grid container spacing={2} columns={14}>
                                    <Grid item xs={0.5}>
                                        <Typography sx={{ fontWeight: "500" }}>{index + 1}{"."}</Typography>
                                    </Grid>
                                    <Grid item lg={3} xs={14} sx={gridAdapting}>
                                        <b>{t("allResults.output.foreignCarNumber")}</b>
                                        <Box>{item.plate_number}</Box>
                                    </Grid>
                                    <Grid item lg={4} xs={14} sx={gridAdapting}>
                                        <Typography sx={{ color: "#ED5321" }}>
                                            <b>{t("allResults.output.paymentSum")}</b>
                                        </Typography>
                                        <Typography sx={{ color: "#ED5321" }}>
                                            {item.violation_ammount}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2.5} xs={14} sx={gridAdapting}>
                                        <b>{t("allResults.output.article")} </b>
                                        {item.violation_article}
                                    </Grid>
                                    <Grid
                                        item
                                        lg={4}
                                        xs={14}
                                        className={style.securityGridModal}
                                    >
                                        <ModalResultByForeignNumber user={item} />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    ))}
                </>
            }
        </Box>
    );
};

export default MainResultByForeignPlate;

const gridAdapting = (theme: Theme) => ({
    [theme.breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
    },
    [theme.breakpoints.down("lg")]: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
    },
});
