import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPenaltiesPlate } from "../../../redux/types/plate-types.types";
import ErrorRoundedIcon from '@mui/icons-material/Error';

import styles from "./total-amount-all-fine-by-number.module.scss"

interface IPlateTotal {
    plateData: IPenaltiesPlate;
}

const TotalAmountAllFineByNumber = ({ plateData }: IPlateTotal) => {
    const { t } = useTranslation();
    const screenWidth = window.innerWidth;
    return (
        <>
            {plateData.penalties.data.erpnProtocols?.length || plateData.penalties.data.bgProtocols?.length ? (
                <>
                    <Box sx={{ behavior: "smooth", display: "flex", justifyContent: "space-between" }}>
                        <h3 className={styles.title}>
                            {t("allResults.titles.result")}
                        </h3>
                        {screenWidth < 900 &&
                            <ErrorRoundedIcon className={styles.errorIcon} />
                        }
                    </Box>
                    {!plateData?.currentInfo?.data &&
                        <Paper elevation={5} sx={{ borderRadius: "12px", padding: "15px 0" }}>
                            <Typography variant={"h5"} sx={{ textAlign: "center", color: "black", textShadow: "1px 2px #a1a09f" }}>
                                {t("allResults.titles.noDataAuto")}
                            </Typography>
                        </Paper>
                    }
                    <Paper
                        elevation={3}
                        sx={{
                            padding: "24px 12px",
                            borderRadius: "8px",
                            display: "flex",
                            backgroundColor: "#F9F9F9",
                            mt: "40px"
                        }}
                    >
                        <Box>
                            <Typography fontWeight="500" className={styles.text}>
                                {t("allResults.output.totalAmount")}
                                <br />
                                {t("allResults.output.finesNumber")}
                            </Typography>
                        </Box>
                        <Box sx={{ color: "#ED5321", marginLeft: "5%" }}>
                            <Typography fontWeight="500" className={styles.text}>
                                {plateData.sumPenalties}
                                <br />
                                {plateData.totalPenalties}
                            </Typography>
                        </Box>
                    </Paper>
                </>
            ) : null}
        </>
    );
};

export default TotalAmountAllFineByNumber;
