import { Button, ButtonProps } from '@mui/material'
import React from 'react'

type Props = ButtonProps & {
    text: string
}

const TolomButton2 = (props: Props) => {
    return (
        <Button {...props} sx={{
            borderRadius: "8px", background: "#101828", fontWeight: "bold",
            "&:hover": { background: "#101828", color: "white", }
        }}>
            {props.text}
        </Button>
    )
}

export default TolomButton2