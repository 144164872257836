import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPenaltiesInn } from "../../../redux/types/pin-types.types";
import ErrorRoundedIcon from '@mui/icons-material/Error';

import styles from "./total-amount-all-fines-by-pin.module.scss"

interface ITotalPinProps {
    pinData: IPenaltiesInn;
}

const TotalAmountAllFineByPin = ({ pinData }: ITotalPinProps) => {
    const { t } = useTranslation();
    const screenWidth = window.innerWidth;
    return (
        <>
            <Box>
                <h3 className={styles.title}>
                    {t("allResults.titles.result")}
                </h3>
                {screenWidth < 900 &&
                    <ErrorRoundedIcon className={styles.errorIcon} />
                }
            </Box>
            {pinData.pssiArest?.success ?
                <Paper
                    elevation={3}
                    sx={{
                        padding: "24px 12px",
                        borderRadius: "8px",
                        backgroundColor: "#F9F9F9",
                        mt: "40px",
                    }}
                >
                    <Typography fontWeight="500" className={styles.text} sx={{ color: "#ED5321", width: '100%', mb: "0.5rem" }}>
                        {t("allResults.output.sendToPSSI")}
                    </Typography>
                    {pinData?.pssiArest?.data?.cases?.map((items, index) => (
                        <Box key={index}>
                            <Typography fontWeight="500" className={styles.text}>
                                {t("payedPenaltyMessage")}
                            </Typography>
                            <Typography fontWeight="500" className={styles.text} sx={{ color: "#ED5321" }}>
                                {items?.court}
                            </Typography>
                        </Box>
                    ))}
                </Paper> : null}
            {pinData?.sumPenalties && pinData?.totalPenalties ?
                <Paper
                    elevation={3}
                    sx={{
                        padding: "24px 12px",
                        borderRadius: "8px",
                        backgroundColor: "#F9F9F9",
                        mt: "40px",
                        display: "flex"
                    }}
                >
                    <Typography fontWeight="500" className={styles.text}>
                        {t("allResults.output.totalAmount")}
                        <br />
                        {t("allResults.output.finesNumber")}
                    </Typography>
                    <Typography fontWeight="500" className={styles.text} sx={{ color: "#ED5321", marginLeft: "5%" }}>
                        {pinData.sumPenalties}
                        <br />
                        {pinData.totalPenalties}
                    </Typography>
                </Paper>
                : null}
        </>
    );
};

export default TotalAmountAllFineByPin;
