import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
    IconButton,
    Box,
    Modal,
    Fade,
    Typography,
    Theme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BgProtocol } from "../../../../redux/types/plate-types.types";
import style from "./modal-result-security-city-by-number.module.scss"
import { TolomButton } from "../../../../components";

interface IBGResultModalProps {
    item: BgProtocol;
}

const ModalResultSecurityCityByNumber = ({ item }: IBGResultModalProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <TolomButton onClick={handleOpen} text={t("allResults.output.erpnModal")} style={{ padding: "10px 15px" }} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box sx={modalAdapting}>
                        <IconButton
                            className={style.close}
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.paymentCode")}: </b>
                            {item.paymentCode}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.deliveryStatus")}: </b>
                            {!item.deliveryDate
                                ? t("allResults.output.notDelivered")
                                : t("allResults.output.deliveredByDate") + item.deliveryDate}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.finesDateBg")}: </b>{new Date(item.violationDate).toLocaleString()}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.finesLocation")}: </b>
                            {item.violationPlace}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.article")}: </b>
                            {item.article}
                        </Typography>
                        {item.part &&
                            <Typography sx={{ mt: 2 }}>
                                <>
                                    <b>{t("allResults.output.part")}: </b>
                                    {item.part}
                                </>
                            </Typography>
                        }
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.offense")}: </b>
                            {item.violationTitle}
                        </Typography>

                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default ModalResultSecurityCityByNumber;

const modalAdapting = (theme: Theme) => ({
    [theme.breakpoints.up(800)]: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        padding: "1rem",
        backgroundColor: "#f6f6f6",
        borderRadius: "7px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    },
    [theme.breakpoints.down(800)]: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "85%",
        padding: "1rem",
        backgroundColor: "#f6f6f6",
        borderRadius: "7px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    },
});
