import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CallIcon from "@mui/icons-material/Call";
import Facebook from "../../assets/icons/face.svg";
import Instagram from "../../assets/icons/insta.svg";
import styles from "./footer.module.scss"

const Footer = () => {
    const { t } = useTranslation();

    return (
        <Box className={styles.mainContainer}>
            <Grid container sx={{ width: "90%", padding: "20px" }}>
                <Grid item xs={12} sx={{ paddingRight: "10px" }}>
                    <Typography className={styles.titles}>{t("footer.about")}</Typography>
                    <Typography className={styles.contactsText} sx={{ marginBottom: "20px", textAlign: "justify" }}>
                        {t("footer.aboutText")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={styles.titles}>
                        {t("footer.contacts")}
                    </Typography>
                    <Box sx={{ width: "100%", display: "flex" }}>
                        <Box sx={{ width: "90%" }}>
                            <Typography className={styles.contactsText} sx={{ display: "flex", gap: "5px" }}>
                                {t("footer.bgFinesList")}
                            </Typography>
                            <Typography sx={{ display: "flex", gap: "5px" }}>
                                <a href="tel:100" style={{ color: "#36cc95", textDecoration: "none", fontWeight: "bold", display: "flex", alignItems: "center" }}> 100</a>
                                <CallIcon sx={{ color: "#36cc95", width: "20px", rotate: "270deg" }} />
                            </Typography>
                            <Typography className={styles.contactsText} mt="2px">
                                {t("footer.callCenterWorkinPeriod")}
                            </Typography>
                            <Box mt="1rem">
                                <Typography className={styles.contactsText} sx={{ mb: "5px" }}>
                                    {t("socialMedia")}
                                </Typography>
                                <Box sx={{ marginLeft: "5px", display: "flex", gap: "20px" }}>
                                    <a href="https://instagram.com/tolom.kg?igshid=NTc4MTIwNjQ2YQ==">
                                        <img src={Instagram} width="16px" height="16px" alt="instagram" />
                                    </a>
                                    <a href="https://www.facebook.com/profile.php?id=100088957020135&mibextid=LQQJ4d">
                                        <img src={Facebook} width="10px" height="16px" alt="facebook" />
                                    </a>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;
