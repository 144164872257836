import { Box, Typography } from '@mui/material'
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import React from 'react'

import styles from "./info-text.module.scss"

interface IProps {
    image: string;
    title: string;
    text1: string;
    text2: string;
}

const InfoText = (props: IProps) => {
    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", }}>
                <LiveHelpIcon sx={{ color: "#36cc95", fontSize: "33px" }} />
            </Box>
            <Typography className={styles.question} sx={{ fontWeight: "500", mb: "40px" }}>
                {props.title}
            </Typography>
            <Typography className={styles.text} sx={{ mb: "24px" }}>
                {props.text1}
            </Typography>
            <Typography className={styles.text} sx={{ mb: "40px" }}>
                {props.text2}
            </Typography>
            <img src={props.image} alt={props.title} width="240px" height="auto" />
        </Box>
    )
}

export default InfoText