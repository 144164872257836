import { configureStore } from "@reduxjs/toolkit";
import getByPlateReducer from "./reducer/get-by-plate";
import getByPinReducer from "./reducer/get-by-pin";
import getByForeignPlateReducer from "./reducer/get-by-foreign-plate";

export const store = configureStore({
  reducer: {
    getByPlateReducer: getByPlateReducer,
    getByPinReducer: getByPinReducer,
    getByForeignPlateReducer: getByForeignPlateReducer,
  },
});

export type TypedRootState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
