import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Modal,
    Fade,
    IconButton,
    Typography,
    Theme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ErpnProtocol } from "../../../../redux/types/plate-types.types";
import { TolomButton } from "../../../../components";
import { useAppSelector } from "../../../../hooks/use-redux";

interface IModalErpnProps {
    user: ErpnProtocol;
}

const ModalResultErpnByNumber = ({ user }: IModalErpnProps) => {
    const vehicleInfo = useAppSelector((state) => state.getByPlateReducer.dataByPlate?.currentInfo.data);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <TolomButton onClick={handleOpen} text={t("allResults.output.erpnModal")} style={{ padding: "10px 15px" }} />
            <Modal
                aria-labelledby="transition-modal-title"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                style={{
                    margin: "5vh 2vw 1vh",
                    overflowY: "auto"
                }}
            >
                <Fade in={open}>
                    <Box sx={modalAdapting}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography>
                                <b>{t("allResults.output.autoBrand")}:</b> {vehicleInfo?.brand}
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Typography sx={{ mt: 1 }}>
                            <b>{t("allResults.output.autoModel")}: </b>
                            {vehicleInfo?.model}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.carNumber")}: </b>
                            {vehicleInfo?.govPlate}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.protocolNumber")}:</b> {user.protocolNumber}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.article")}: </b>
                            {user.article}
                        </Typography>
                        {user.part &&
                            <Typography sx={{ mt: 2 }}>
                                <b>{t("allResults.output.part")}: </b>
                                {user.part}
                            </Typography>
                        }
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.paymentCode")}: </b> {user.paymentCode}
                        </Typography>
                        <Typography sx={{ mt: 2, color: "#ED5321", fontWeight: "500" }}>
                            {t("allResults.output.paymentSum")}:{" "}
                            {user.fineAmountToPay}{" "}
                            {user.penaltyAmount ? (
                                <>
                                    ({user.fineAmount} {t("allResults.output.fines")}
                                    {user.penaltyAmount} {t("allResults.output.penya")})
                                </>
                            ) : null}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <b>{t("allResults.output.finesDate")}</b> {new Date(user?.violationDate).toLocaleString()}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    );
};

export default ModalResultErpnByNumber;

const modalAdapting = (theme: Theme) => ({
    [theme.breakpoints.up(800)]: {

        margin: "0 auto",
        width: "50%",
        padding: "1rem",
        backgroundColor: "#f6f6f6",
        borderRadius: "7px",
    },
    [theme.breakpoints.down(800)]: {
        margin: "0 auto",
        width: "90%",
        padding: "1rem",
        backgroundColor: "#f6f6f6",
        borderRadius: "7px",
    },
});
