import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IForeignPlate,
  IRequestForeignPlate,
} from "../types/foreign-plate-types.types";

interface IDataByPin {
  dataByForeignPlate: IForeignPlate[] | undefined;
  isLoading: boolean;
  isError: string;
  reloadCaptcha: boolean;
}

const initialState: IDataByPin = {
  dataByForeignPlate: undefined,
  isLoading: false,
  isError: "",
  reloadCaptcha: true,
};

export const getByForeignPlateAsync = createAsyncThunk(
  "get-by-plate/getByForeignPlate",
  async function (request: IRequestForeignPlate, thunkAPI) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/penalty/by-plate-foreign`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(request),
        }
      );
      if (!response.ok) {
        throw new Error(`${response.status}`);
      }
      return await response.json();
    } catch (error: any) {
      switch (error.message) {
        case "500":
          return thunkAPI.rejectWithValue("Сервис временно недоступен!");
        case "404":
          return thunkAPI.rejectWithValue(
            "Штрафы по данному иностранному гос. номеру не найдены"
          );
        default:
          return thunkAPI.rejectWithValue("Подключитесь к интернету!");
      }
    }
  }
);

export const getByForeignPlateReducer = createSlice({
  name: "get-by-foreign-plate",
  initialState,
  reducers: {
    resetForeignPlateData: (state) => {
      state.dataByForeignPlate = undefined;
      state.isError = "";
    },
  },
  extraReducers: {
    [getByForeignPlateAsync.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = "";
      state.reloadCaptcha = false;
    },
    [getByForeignPlateAsync.fulfilled.type]: (
      state,
      action: PayloadAction<IForeignPlate[]>
    ) => {
      state.dataByForeignPlate = action.payload;
      state.isLoading = false;
      state.isError = "";
      state.reloadCaptcha = true;
    },
    [getByForeignPlateAsync.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.isError = action.payload;
      state.reloadCaptcha = true;
    },
  },
});

export const { resetForeignPlateData } = getByForeignPlateReducer.actions;
export default getByForeignPlateReducer.reducer;
