import React from "react";
import logo from "../../assets/icons/logoL.svg";
import { Box, AppBar, Toolbar, Typography, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { LangChange } from "../index";
import { useTranslation } from "react-i18next";
import MenuIcon from '@mui/icons-material/Menu';
import Facebook from "../../assets/icons/face.svg";
import Instagram from "../../assets/icons/insta.svg";

import styles from "./header.module.scss"

const Header2 = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const { t, i18n } = useTranslation();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="sticky" sx={{ height: "100px", background: { xs: "rgba(255, 255, 255, 0.6)", lg: "white" }, boxShadow: "none", color: "#101828", padding: { xs: "0", md: "0 4%" } }}>
            <Toolbar sx={{ height: "100%" }}>
                <a href="/">
                    <img alt="tolom" src={logo} width="100px" height="100px" />
                </a>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
                    <Box sx={{ display: "flex", width: "40%" }}>
                        <Typography className={styles.title}>
                            {t("main.headTitleTolom")}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", width: "60%", gap: "20px", justifyContent: "flex-end" }}>
                        <a href={i18n.language === "ru" ? "/penyaRU.pdf" : "/penyaKG.pdf"} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}>
                            <Typography className={styles.links}>
                                {t("faq.penya")}
                            </Typography>
                        </a>
                        <a href="#FAQ" style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}>
                            <Typography className={styles.links}>
                                {t("faq.title")}
                            </Typography>
                        </a>
                        <a href="#Contacts" style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}>
                            <Typography className={styles.links}>
                                {t("footer.contacts")}
                            </Typography>
                        </a>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
                            <Box sx={{ height: "40%", display: "flex", gap: "15px" }}>
                                <Divider orientation="vertical" color="#101828" />
                                <a href="https://www.facebook.com/profile.php?id=100088957020135&mibextid=LQQJ4d" style={{ color: "black", display: "flex", alignItems: "center" }}>
                                    <img src={Facebook} width="9px" height="18px" alt="facebook" />
                                </a>
                                <a href="https://instagram.com/tolom.kg?igshid=NTc4MTIwNjQ2YQ==" style={{ color: "red", display: "flex", alignItems: "center" }}>
                                    <img src={Instagram} width="16px" height="16px" alt="instagram" />
                                </a>
                            </Box>
                            <LangChange />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' }, justifyContent: "flex-end" }}>
                    <IconButton
                        id="basic-button"
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{ display: { xs: 'block', lg: 'none' } }}
                    >
                        <MenuItem onClick={handleCloseNavMenu}>
                            <a href={i18n.language === "ru" ? "/penyaRU.pdf" : "/penyaKG.pdf"} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}>
                                <Typography sx={{ fontSize: "16px", lineHeight: "24px", textAlign: "center" }}>
                                    {t("faq.penya")}
                                </Typography>
                            </a>
                        </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu}>
                            <a href="#FAQ" style={{ textDecoration: "none", color: "inherit" }}>
                                <Typography sx={{ fontSize: "16px", lineHeight: "24px", textAlign: "center" }}>
                                    {t("faq.title")}
                                </Typography>
                            </a>
                        </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu}>
                            <a href="#Contacts" style={{ textDecoration: "none", color: "inherit" }}>
                                <Typography sx={{ fontSize: "16px", lineHeight: "24px", textAlign: "center" }}>
                                    {t("footer.contacts")}
                                </Typography>
                            </a>
                        </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <LangChange />
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar >
    );
};

export default Header2;