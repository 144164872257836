import ErrorRoundedIcon from '@mui/icons-material/Error';
import { Box, Grid, Paper, Theme, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { IPenaltiesPlate, ResponseList } from "../../../redux/types/plate-types.types";
import { Parking } from '../parking/parking';
import styles from "./data-user-by-number.module.scss";

interface ICurrentInfoProps {
    plateData: IPenaltiesPlate;
    plateLoading: boolean;
}

const DataUserByNumber = ({ plateData, plateLoading }: ICurrentInfoProps) => {
    const { t } = useTranslation();
    const screenWidth = window.innerWidth;
    return (
        <React.Fragment>
            {!plateLoading ? (
                <>
                    {plateData.currentInfo?.data && (
                        <>
                            <Box sx={{ behavior: "smooth", display: "flex", justifyContent: "space-between", mb: "30px" }}>
                                <h3 className={styles.title}>
                                    {t("allResults.titles.result")}
                                </h3>
                                {screenWidth < 900 &&
                                    <ErrorRoundedIcon className={styles.errorIcon} />
                                }
                            </Box>

                            {plateData?.parking?.success ? <Parking adress={plateData?.parking?.data?.parkingAddress} /> : null}

                            <Paper elevation={3} className={styles.dataUserByNumberPaper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={11}>
                                        <Grid container spacing={1} sx={{ padding: "10px 0" }}>
                                            <Grid item xs={5}>
                                                <Typography className={styles.titleStyle} sx={fontAdapting}>
                                                    <b>{t("allResults.output.autoNumber")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography sx={fontAdapting}>
                                                    {plateData?.currentInfo?.data?.govPlate}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={styles.titleStyle} sx={fontAdapting}>
                                                    <b>{t("allResults.output.autoBrand")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography sx={fontAdapting}>
                                                    {plateData?.currentInfo?.data?.brand}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={styles.titleStyle} sx={fontAdapting}>
                                                    <b>{t("allResults.output.autoModel")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography sx={fontAdapting}>
                                                    {plateData?.currentInfo?.data?.model}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={styles.titleStyle} sx={fontAdapting}>
                                                    <b>{t("allResults.output.steeringWheel")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography sx={fontAdapting}>
                                                    {plateData?.currentInfo?.data?.steering}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={styles.titleStyle} sx={fontAdapting}>
                                                    <b>{t("allResults.output.autoColor")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography sx={fontAdapting}>
                                                    {plateData?.currentInfo?.data?.color}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={styles.titleStyle} sx={fontAdapting}>
                                                    <b>{t("allResults.output.yearOfIssue")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography sx={fontAdapting}>
                                                    {plateData?.currentInfo?.data?.year}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={styles.titleStyle} sx={fontAdapting}>
                                                    <b>{t("allResults.output.autoType")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography sx={fontAdapting}>
                                                    {plateData?.currentInfo?.data?.carTypeName}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={styles.titleStyle} sx={fontAdapting}>
                                                    <b>{t("allResults.output.autoVolume")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography sx={fontAdapting}>
                                                    {plateData?.currentInfo?.data?.engineVolume}
                                                </Typography>
                                            </Grid>
                                            {plateData?.period?.data ? (
                                                <Grid item xs={12} sx={{ mt: 2 }}>
                                                    <Grid item xs={12}>
                                                        <Typography sx={fontAdapting}>
                                                            <b>{t("allResults.output.autoUsingPeriod")}</b>
                                                        </Typography>
                                                    </Grid>
                                                    {plateData?.period?.data?.responseList?.map((item: ResponseList, id: number) => (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{ display: "flex", gap: 1, mt: 1, padding: "2px" }}
                                                            key={id}
                                                        >
                                                            <Typography
                                                                sx={{ display: "inline" }}
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {id + 1}{")"} {t("allResults.output.from")} {item.dateFrom}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ display: "inline" }}
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {item.dateTo === ""
                                                                    ? t("allResults.output.untilThisDate")
                                                                    : t("allResults.output.until") + item.dateTo}{" "}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            ) : null}
                                            <Grid item xs={5}>
                                                <Typography className={styles.toningAcceptance} sx={fontAdapting}>
                                                    <b>{t("allResults.output.tintinAgrement")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                {plateData.tintingWidow.data !== "Разрешение отсутствует" ? (
                                                    <Typography style={{ color: "#36cc95" }} sx={fontAdapting}>
                                                        {plateData.tintingWidow.data}
                                                    </Typography>
                                                ) : (
                                                    <Typography style={{ color: "#EA4335" }} sx={fontAdapting}>
                                                        {plateData.tintingWidow.data}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography
                                                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                                                >
                                                    <b>{t("allResults.output.type")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                {plateData.arestInfo.data ? (
                                                    <Typography color="#EA4335">{plateData?.arestInfo?.data?.type}</Typography>
                                                ) : "-"}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </>
                    )}
                </>
            ) : null
            }
        </React.Fragment >
    );
}

export default DataUserByNumber;

const fontAdapting = (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
    },
});
