import React, { useEffect } from "react";
import { Box, Grid, TextField, Theme, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styles from "./check-by-number.module.scss"
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/use-redux";
import { getByPlateAsync, resetPlateData } from "../../../redux/reducer/get-by-plate";
import { SubmitHandler, useForm } from "react-hook-form";
import { TolomButton2 } from "../../../components";
import ReCAPTCHA from "react-google-recaptcha";

interface IProps {
    captcha: boolean;
    setCaptcha: React.Dispatch<React.SetStateAction<boolean>>;
    captchaChange: (token: string | null) => void;
    captchaPlate: boolean;
    captchaPin: boolean;
    foreignPaptchaPlate: boolean
    reRef: React.MutableRefObject<any>;
    token: string | null;
}

interface IPlate {
    plateNumber: string;
}

const CheckByNumber = ({ captcha, setCaptcha, captchaChange, captchaPlate, captchaPin, reRef, token, foreignPaptchaPlate }: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const { isLoading: plateLoading } = useAppSelector(state => state.getByPlateReducer)
    const { register, handleSubmit, formState: { errors } } = useForm<IPlate>({ mode: "onBlur" });

    const handleSearchByPlate: SubmitHandler<IPlate> = (data) => {
        const PlateNumber = data.plateNumber.toUpperCase()
        const request = {
            plate: PlateNumber.toUpperCase(),
            token: token
        }
        if (!captcha) {
            dispatch(getByPlateAsync(request))
            setCaptcha(true);
        }
    }

    useEffect(() => {
        dispatch(resetPlateData())
    }, [dispatch])

    return (
        <Grid item xs={12}>
            <Typography className={styles.text} sx={{ fontWeight: "400", mb: { xs: "24px", md: "56px" } }}>
                {t("main.text.finesCarNumber")}
            </Typography>
            <form onSubmit={handleSubmit(handleSearchByPlate)}>
                <Box>
                    <Grid container spacing={0} sx={mainGrid}>
                        <Grid item xs={12}>
                            <h5 className={styles.title}>
                                {t("main.input.carNumber")}
                            </h5>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "10px" }}>
                            <TextField
                                id="outlined-basic"
                                label="07KG015XAM"
                                variant="outlined"
                                fullWidth={true}
                                sx={InputAdapting}
                                InputLabelProps={{ required: false }}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                {...register("plateNumber", {
                                    required: t("allResults.validation.enterPlate"),
                                    maxLength: {
                                        value: 12,
                                        message: t("allResults.validation.wrongPlate")
                                    },
                                })}
                            />
                            {errors?.plateNumber && <Box sx={{ position: "absolute", color: "red", fontSize: "12px" }}>{errors?.plateNumber?.message}</Box>}
                        </Grid>
                        <Grid item xs={12} lg={6} className={styles.captchaPlate} sx={{ paddingTop: { xs: "10px", md: "20px" } }}>
                            {captchaPlate && captchaPin && foreignPaptchaPlate ?
                                <Box>
                                    <ReCAPTCHA
                                        sitekey="6LcptCIjAAAAAAWfi-MN_0ceGjgzTcz90wOdDwJN"
                                        onChange={captchaChange}
                                        ref={reRef}
                                        size="normal"
                                        className={styles.captchaSize}
                                    />
                                </Box>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12} xl={5} sx={{ paddingTop: "10px" }}>
                            {!plateLoading ? (
                                <Grid item xs={12}>
                                    <TolomButton2 text={t("main.button.check")}
                                        fullWidth={true}
                                        type="submit"
                                        style={{ padding: "12px 24px", color: "white" }}
                                        disabled={captcha} />
                                </Grid>
                            ) : (
                                <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                                    <LoadingButton
                                        loading
                                        variant="contained"
                                        fullWidth={true}
                                        type="submit"
                                        sx={{ borderRadius: "8px", background: "#101828", color: "white", fontWeight: "bold" }}
                                    >
                                        {t("main.button.loading")}
                                    </LoadingButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Grid>
    );
};

export default CheckByNumber;

const InputAdapting = (theme: Theme) => ({
    background: "rgba(54, 179, 166, 0.1)",
    [theme.breakpoints.up(2500)]: {
        "& .MuiInputLabel-root": {
            fontSize: "22px",
        },
        "& .MuiInputBase-root": {
            height: "80px",
        },
        "& .MuiOutlinedInput-input": {
            fontSize: "32px",
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "WebkitAppearance": "none",
            },
        },
    },
    [theme.breakpoints.down(2500)]: {
        "& .MuiInputBase-root": {
            height: "56px",
        },
        "& .MuiOutlinedInput-input": {
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "WebkitAppearance": "none",
            },
        }
    },
});

const mainGrid = (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    padding: "0",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
        justifyContent: "center",
    }
})