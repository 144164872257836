import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import FaqCard from './faq-card'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import styles from "./faq.module.scss"

interface IFaqTxt {
    question: string;
    answer: string;
    varr: string;
}

export const FaqBlock = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const { t } = useTranslation();
    const screenWidth = window.innerWidth;

    const faqTextArray: IFaqTxt[] = [
        { question: t("faq.questions.first"), answer: t("faq.answers.firstt"), varr: "panel1" },
        { question: t("faq.questions.second"), answer: t("faq.answers.second"), varr: "panel2" },
        { question: t("faq.questions.third"), answer: t("faq.answers.third"), varr: "panel3" },
        { question: t("faq.questions.fourth"), answer: t("faq.answers.fourth"), varr: "panel4" },
        { question: t("faq.questions.fifth"), answer: t("faq.answers.fifth"), varr: "panel5" }]

    const slideLeft = () => {
        const slider: any = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft - 500;
    }
    const slideRight = () => {
        const slider: any = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft + 500;
    }

    const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ width: "100%", mb: "30px" }}>
            <Box sx={{ width: "90%", display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "500", mb: "50px", fontSize: "24px", color: "#101828", textTransform: "uppercase" }}>
                    {t("answerQuestion")}
                </Typography>
                {screenWidth > 900 &&
                    <Box sx={{ display: "flex", gap: "20px" }}>
                        <KeyboardArrowLeft sx={{ cursor: "pointer", "&:hover": { transform: "scale(1.2)" } }} onClick={slideLeft} />
                        <KeyboardArrowRight sx={{ cursor: "pointer", "&:hover": { transform: "scale(1.2)" } }} onClick={slideRight} />
                    </Box>
                }
            </Box>
            <Box id="slider" sx={{ display: { sx: "block", md: "flex" }, gap: "32px", overflowX: "scroll", padding: "1rem" }} className={styles.horizontalScrol}>
                {faqTextArray.map((items: IFaqTxt, index) => (
                    <Box key={index}>
                        <FaqCard question={items.question} answear={items.answer} varr={items.varr} handleChangeAccordion={handleChangeAccordion} expanded={expanded} />
                    </Box>
                ))}
            </Box>
        </Box >
    )
}

export default FaqBlock;