import { Typography, Box } from '@mui/material'
import "../styleee.scss"

const TechWork = () => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", paddingTop: { xs: "50%", md: "15%" }, width: "100%" }}>
            <Typography sx={{ fontSize: { xs: "25px", md: "50px" }, background: "none", textAlign: "center", color: "black", mb: "50px", width: "100%" }}>
                Ведутся технические работы
            </Typography>
            <Box sx={{ position: "relative" }}>
                <div className="showbox">
                    <div className="loader">
                        <svg className="circular" viewBox="25 25 50 50">
                            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
                        </svg>
                    </div>
                </div>
            </Box>
        </Box>
    )
}

export default TechWork