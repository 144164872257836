import React from "react";
import { Grid, Box } from "@mui/material";
import styles from "./text.module.scss";

interface IText {
    text: string;
    title: string;
    imag?: string;
}

const Texts = ({ text, title, imag }: IText) => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12}>
                <h2 className={styles.title}>
                    <strong>{title}</strong>
                </h2>
                <br />
                {imag ?
                    <Grid container>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
                            <h4 className={styles.text}>
                                {text}
                            </h4>
                        </Grid>
                        <Grid item xs={12} sm={6} className={styles.imagesBox}>
                            <img alt="Images" height="100%" src={imag} className={styles.imageStyle} />
                        </Grid>
                    </Grid>
                    : <h4 className={styles.text}>
                        {text}
                    </h4>}
            </Grid>
        </Box>
    );
};

export default Texts;