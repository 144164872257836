import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPenaltiesPlate } from "../../../redux/types/plate-types.types";
import {
    DataUserByNumber,
    DataUserByNumberOther,
    ResultErpnByNumber,
    TotalAmountAllFineByNumber,
} from "../../index";

interface IPlateResProps {
    plateLoading: boolean;
    plateData: IPenaltiesPlate;
    plateError?: boolean | any;
}

const MainResultByPlate = ({ plateData, plateLoading }: IPlateResProps) => {
    const { t } = useTranslation();
    return (
        <>
            {(plateData?.currentInfo !== null && plateData?.penalties?.data?.bgProtocols !== null && plateData?.penalties?.data?.erpnProtocols?.length !== 0)
                ?
                <Box sx={{ mt: "4rem", width: "100%" }}>
                    {plateData?.sumPenalties ? (
                        <Box sx={{ marginBottom: "40px" }}>
                            <TotalAmountAllFineByNumber plateData={plateData} />
                        </Box>
                    ) : null}
                    {plateData?.currentInfo ? (
                        <DataUserByNumber plateLoading={plateLoading} plateData={plateData} />
                    ) : null}
                    {!!plateData?.currentInfo && plateData.transportInfoOther ? (
                        <DataUserByNumberOther plateLoading={plateLoading} plateData={plateData} />
                    ) : null}

                    {(plateData?.currentInfo?.data || plateData?.penalties?.data?.erpnProtocols || plateData?.penalties?.data?.bgProtocols) ? (
                        <ResultErpnByNumber
                            plateData={plateData}
                            plateLoading={plateLoading}
                        />
                    ) : null}
                    {(plateData?.currentInfo?.data === null && plateData?.penalties?.data === null && plateData?.penalties?.data === null && plateData.transportInfoOther.data === null) && (
                        <Paper elevation={5} sx={{ borderRadius: "12px", padding: "15px 0" }}>
                            <Typography variant={"h5"} sx={{ textAlign: "center", color: "black", textShadow: "1px 2px #a1a09f" }}>
                                {!plateData.currentInfo?.data && t("allResults.titles.noData")}
                            </Typography>
                        </Paper>
                    )}
                </Box>
                : (plateData?.currentInfo === null && plateData?.penalties?.data?.erpnProtocols === null && plateData?.penalties?.data?.bgProtocols === null) ?
                    <Box sx={{ mt: "4rem", width: "100%" }}>
                        <Paper elevation={5} sx={{ borderRadius: "12px", padding: "15px 0" }}>
                            <Typography variant={"h5"} sx={{ textAlign: "center", color: "black", textShadow: "1px 2px #a1a09f" }}>
                                {t("allResults.titles.noData")}
                            </Typography>
                        </Paper>
                    </Box>
                    :
                    <Box sx={{ mt: "4rem", width: "100%" }}>
                        {
                            plateData?.currentInfo === null && (
                                <Paper elevation={5} sx={{ borderRadius: "12px", padding: "15px 0" }}>
                                    <Typography variant={"h5"} sx={{ textAlign: "center", color: "black", textShadow: "1px 2px #a1a09f" }}>
                                        {t("allResults.titles.noDataAuto")}
                                    </Typography>
                                </Paper>
                            )
                        }

                        {plateData?.sumPenalties ? (
                            <Box sx={{ marginBottom: "40px", mt: "4rem" }}>
                                <TotalAmountAllFineByNumber plateData={plateData} />
                            </Box>
                        ) : null}
                        {plateData?.currentInfo ? (
                            <DataUserByNumber plateLoading={plateLoading} plateData={plateData} />
                        ) : null}
                        {!!plateData?.currentInfo && plateData?.transportInfoOther ? (
                            <DataUserByNumberOther plateLoading={plateLoading} plateData={plateData} />
                        ) : null}

                        {(plateData?.currentInfo?.data?.govPlate || plateData?.penalties?.data?.erpnProtocols) ? (
                            <Box>
                                {(plateData?.penalties?.data?.erpnProtocols || plateData?.penalties?.data?.bgProtocols) && (
                                    <ResultErpnByNumber
                                        plateData={plateData}
                                        plateLoading={plateLoading}
                                    />
                                )}
                            </Box>
                        ) : null}
                    </Box>
            }
        </>
    );
};

export default MainResultByPlate;
