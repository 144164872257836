import React, { useState, useEffect } from "react";
import {
    Box,
    Modal,
    Fade,
    Theme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode"
import { TolomButton } from "../../../../components";

interface IModalErpnProps {
    paymentCode: string;
}

const PaymentModal = ({ paymentCode }: IModalErpnProps) => {
    const [open, setOpen] = React.useState(false);
    const [qr, setQr] = useState<string>("");
    useEffect(() => {
        QRCode.toDataURL(`ERPN_${paymentCode}`).then((data) => {
            setQr(data);
        })
    }, [paymentCode])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <TolomButton text={t("allResults.output.erpnPay")} onClick={handleOpen} style={{ padding: "10px 15px" }} />
            <Modal
                aria-labelledby="transition-modal-title"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                sx={{ overflow: "auto" }}
            >
                <Fade in={open}>
                    <Box sx={modalAdapting}>
                        <img src={qr} width="100%" alt="qr" height="100%" style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.6)" }} />
                        <Box onClick={handleClose} sx={closeIcon}>
                            &#x2716;
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    );
};

export default PaymentModal;

const modalAdapting = (theme: Theme) => ({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up(1200)]: {
        width: "20%",
    },
    [theme.breakpoints.down(1200)]: {
        width: "30%",
    },
    [theme.breakpoints.down(800)]: {
        width: "70%",
    },
});


const closeIcon = (theme: Theme) => ({
    position: 'absolute' as 'absolute',
    top: '4%',
    left: '96%',
    transform: 'translate(-50%, -50%)',
    fontSize: "20px",
    cursor: "pointer",
    color: "green",
    "&:hover": {
        color: "red"
    }
});
