import React from "react";
import { Box, Grid, Paper, Theme, Typography } from "@mui/material";
import { ModalResultErpnByPin } from "../../index";
import { useTranslation } from "react-i18next";
import style from "./result-erpn-by-pin.module.scss"
import { IPenaltiesInn, ErpnProtocol } from "../../../redux/types/pin-types.types";

interface IERNREsultProps {
    pinData: IPenaltiesInn | undefined;
    pinLoading: boolean;
}

const ResultErpnByPin = ({ pinData, pinLoading }: IERNREsultProps) => {
    const { t } = useTranslation();
    return (
        <>
            {pinLoading ? null : (
                <>
                    <>
                        <Typography sx={{ fontWeight: "500", fontSize: "22px", color: "#101828", mb: "30px", mt: "60px", maxWidth: "780px" }} className={style.title}>
                            {t("allResults.titles.erpnFines")}
                        </Typography>
                        <>
                            {pinData?.penalties.data.erpnProtocols.map((user: ErpnProtocol, i: number) => (
                                <Box
                                    key={`_${user.paymentCode}`}
                                    sx={{ display: "flex", gap: "8px" }}
                                >
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            padding: "20px 10px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "15px",
                                            marginBottom: "20px",
                                            color: "#101828",
                                            width: "100%",
                                            borderRadius: "8px",
                                            background: "#F9F9F9"
                                        }}
                                    >
                                        <Grid container spacing={2} columns={14}>
                                            <Grid item xs={0.5}>
                                                <Typography sx={{ fontWeight: "500" }}>{i + 1}{"."}</Typography>
                                            </Grid>
                                            <Grid item lg={4} xs={14} sx={gridsAdapting}>
                                                <Typography sx={fontAdapting}>
                                                    <b>{t("allResults.output.protocolNumber")}</b>
                                                </Typography>
                                                <Typography sx={fontAdapting}>
                                                    {user.protocolNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={14} sx={gridsAdapting}>
                                                <Typography sx={fontAdapting}>
                                                    <b>{t("allResults.output.article")}</b>
                                                </Typography>
                                                <Typography sx={fontAdapting}>
                                                    {user.article}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={14} sx={gridsAdapting}>
                                                <Typography sx={fontAdapting}>
                                                    <b>{t("allResults.output.paymentCode")}</b>
                                                </Typography>
                                                <Typography sx={fontAdapting}>
                                                    {user?.paymentCode}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={14} md={6} sx={gridsAdapting}>
                                                <Typography sx={{ color: "#ED5321", fontWeight: "500" }}>
                                                    {t("allResults.output.paymentSum")}{" "}
                                                    {user.fineAmountToPay}
                                                    {user.penaltyAmount ? (
                                                        <>
                                                            ({user.fineAmount}{" "}
                                                            {t("allResults.output.fines")}
                                                            {user.penaltyAmount}{" "}
                                                            {t("allResults.output.penya")})
                                                        </>
                                                    ) : null}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={14}
                                                md={8}
                                                lg={8}
                                                className={style.gridSelect}
                                            >
                                                <ModalResultErpnByPin user={user} />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Box>
                            ))}
                        </>
                    </>
                </>
            )
            }
        </ >
    );
};

export default ResultErpnByPin;

const gridsAdapting = (theme: Theme) => ({
    [theme.breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
    },
    [theme.breakpoints.down("lg")]: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    [theme.breakpoints.down("sm")]: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
});

const fontAdapting = (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
    },
});
