import { Button, ButtonProps } from '@mui/material'
import React from 'react'

type Props = ButtonProps & {
    text: string
}

const TolomButton = (props: Props) => {
    return (
        <Button {...props} sx={{
            borderRadius: "8px", background: "#101828", color: "white", fontWeight: "bold",
            "&:hover": { backgroundColor: "none", color: "#36cc95", border: "1px solid #36cc95" }
        }}>
            {props.text}
        </Button>
    )
}

export default TolomButton